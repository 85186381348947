//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import HeroSection from '~/components/HeroSection.vue';
import FCImage from '~/components/FCImage.vue';

export default {
  name: 'Experience_Social',
  components: {
    HeroSection,
    FCImage,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
  },
};
