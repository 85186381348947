//
//
//
//
//
//
//
//
//
//
//
//
//

import BackgroundResponsive from '~/components/BackgroundResponsive.vue';

export default {
  name: 'HeroSection',
  components: {
    BackgroundResponsive,
  },
  props: {
    img: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      content: {
        background: {
          mobile_img_url: this.img,
          desktop_img_url: this.img,
          default: `https://cdn3.joinfightcamp.com/800x0/${this.img}`,
        },
      },
    };
  },
};
